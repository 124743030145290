import {Box, Grid, GridProps} from '@mui/material';
import {memo} from 'react';
import styled from 'styled-components';

import BarImage from '../../../../assets/icons/DesktopBanner.png';

function RightBar() {
  const RightBarWrapper = styled(Box)`
    && {
      height: 100%;
      position: fixed;
      padding: 0;
      right: 0;
    }
  `;

  const GridItem = styled(Grid)<GridProps>`
    && {
      @media (max-width: 480px) {
        display: none;
      }
    }
  `;

  return (
    <GridItem item data-testid='right-bar'>
      <RightBarWrapper>
        <img src={BarImage} alt='rightBar' width='100%' height='100%' />
      </RightBarWrapper>
    </GridItem>
  );
}

export default memo(RightBar);
