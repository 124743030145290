import {CircularProgress} from '@mui/material';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Adjust the z-index as needed */
`;

const StyledLoader = styled(CircularProgress)`
  && {
    color: '#4650ef';
  }
`;

export {StyledLoader, LoaderWrapper};
