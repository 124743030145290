import Loader from '@shared/components/Loader';
import {RootState} from '@store';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import AppContainer from './App.style';
import AppRoutes from './routes';

export default function App() {
  const {pathname} = useLocation();

  useEffect(() => {
    document.body.style.cssText = 'zoom: 100%';

    // Clean up the style on component unmount
    return () => {
      document.body.style.cssText = '';
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const {
    loader: {isAppLoading},
  } = useSelector((state: RootState) => state);
  return (
    <AppContainer>
      <I18nextProvider i18n={i18n}>
        <AppRoutes />
        {isAppLoading && <Loader />}
      </I18nextProvider>
    </AppContainer>
  );
}
