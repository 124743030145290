import {Modal} from '@mui/material';
import BlackCloseIcon from '@assets/icons/BlackCloseIcon.svg';
import BlackLogout from '@assets/icons/BlackLogout.svg';
import {useTranslation} from 'react-i18next';
import {
  StyleModalTItle,
  StyledButton,
  StyledCloseButton,
  StyledContent,
  StyledModalContent,
} from './index.style';

interface ILogoutModal {
  isOpen: boolean;
  handleLogout: () => void;
  handleCancle: () => void;
}

export default function LogoutModal({
  isOpen,
  handleLogout,
  handleCancle,
}: ILogoutModal) {
  const {t} = useTranslation();

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <StyledModalContent>
        <StyledCloseButton onClick={handleCancle}>
          <img src={BlackCloseIcon} alt='logout-modal-close' />
        </StyledCloseButton>
        <StyledContent>
          <img src={BlackLogout} alt='logout-icon' />
          <StyleModalTItle>{t('logout.MP_LOGOUT_NOTE')}</StyleModalTItle>
        </StyledContent>
        <StyledButton isLogoutButton onClick={handleLogout}>
          {t('logout.MP_LOGOUT_BTN')}
        </StyledButton>
        <StyledButton isLogoutButton={false} onClick={handleCancle}>
          {t('logout.MP_LOGOUT_CANCEL_BTN')}
        </StyledButton>
      </StyledModalContent>
    </Modal>
  );
}
