import {AUTH_API_ENDPOINTS, VERSIONS} from '@common/constants';
import {get, post} from '@core/api/axios';
import {AxiosResponse} from 'axios';

export const loginApi = async (data: any) => {
  try {
    const response = await post(
      `${VERSIONS.V1}${AUTH_API_ENDPOINTS.LOGIN}`,
      data
    );
    return response as AxiosResponse;
  } catch (error) {
    return error as AxiosResponse;
  }
};
export const selectBinApi = async ({seedflexMID, token}: any) => {
  const response = await post(
    `${VERSIONS.V1}${AUTH_API_ENDPOINTS.SELECT_BIN}`,
    {seedflexMID},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response as AxiosResponse;
};

export const getCountriesList = async (data: string) => {
  const response = await get(
    `${VERSIONS.V1}${AUTH_API_ENDPOINTS.GET_COUNTRIES}?country=${data}`
  );
  return response as AxiosResponse;
};

export const validateMobileEmail = async (data: any, countryCode: string) => {
  try {
    const response = await post(
      `${VERSIONS.V1}${AUTH_API_ENDPOINTS.VALIDATE_MOBILE}`,
      data,
      {
        headers: {
          CountryCode: countryCode,
        },
      }
    );
    return response as AxiosResponse;
  } catch (error: any) {
    return error?.data?.message;
  }
};

export const logoutApi = async () => {
  try {
    const response = await post(`${VERSIONS.V1}${AUTH_API_ENDPOINTS.LOGOUT}`);
    return response as AxiosResponse;
  } catch (error) {
    return error as AxiosResponse;
  }
};
