/* eslint-disable sonarjs/cognitive-complexity */
import {createSlice} from '@reduxjs/toolkit';

const salesPlatform = createSlice({
  name: 'salesPlatform',
  initialState: {
    businessName: '',
    businessIdentificationNumber: '',
    yearOfIncorporation: '',
    industry: '',
    subIndustry: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    country: '',
    state: '',
    city: '',
    gender: '',
    dob: '',
    email: '',
    phoneNumber1: '',
    phoneNumber2: '',
    mobileCode: '',
    firstName: '',
    lastName: '',
    nric: '',
    prospectStatus: '',
  },
  reducers: {
    setSalesPlatformPiiData: (stateData, action) => {
      const {
        businessName,
        businessIdentificationNumber,
        yearOfIncorporation,
        industry,
        subIndustry,
        addressLine1,
        addressLine2,
        postalCode,
        country,
        state,
        city,
        gender,
        dob,
        email,
        phoneNumber1,
        phoneNumber2,
        mobileCode,
        firstName,
        lastName,
        nric,
        prospectStatus,
      } = action.payload;
      stateData.businessName = businessName || '';
      stateData.businessIdentificationNumber =
        businessIdentificationNumber || '';
      stateData.yearOfIncorporation = yearOfIncorporation || '';
      stateData.industry = industry || '';
      stateData.subIndustry = subIndustry || '';
      stateData.addressLine1 = addressLine1 || '';
      stateData.addressLine2 = addressLine2 || '';
      stateData.postalCode = postalCode || '';
      stateData.country = country || '';
      stateData.state = state || '';
      stateData.city = city || '';
      stateData.gender = gender || '';
      stateData.dob = dob || '';
      stateData.email = email || '';
      stateData.phoneNumber1 = phoneNumber1 || '';
      stateData.phoneNumber2 = phoneNumber2 || '';
      stateData.mobileCode = mobileCode || '';
      stateData.firstName = firstName || '';
      stateData.lastName = lastName || '';
      stateData.nric = nric || '';
      stateData.prospectStatus = prospectStatus || '';
    },
  },
});

const salesPlatformReducer = salesPlatform.reducer;

export const salesPlatformActions = salesPlatform.actions;

export default salesPlatformReducer;
