import {createSlice} from '@reduxjs/toolkit';

const repayment = createSlice({
  name: 'repayment',
  initialState: {
    id: null,
    grossLoanAmount: null, // you will pay
    remainingRepaymentAmountTillDate: null,
    loanStatus: null,
    loanAmount: null,
    bankName: null,
    van: null,
    overdueAmountTillDate: null,
    vanRoutingCodeType: null,
    vanRoutingCodeValue: null,
  },
  reducers: {
    setRepaymentData: (state, action) => {
      const {
        grossLoanAmount,
        remainingRepaymentAmountTillDate,
        loanStatus,
        loanAmount,
        bankName,
        van,
        overdueAmountTillDate,
        vanRoutingCodeType,
        vanRoutingCodeValue,
      } = action.payload;

      state.grossLoanAmount = grossLoanAmount;
      state.remainingRepaymentAmountTillDate = remainingRepaymentAmountTillDate;
      state.loanStatus = loanStatus;
      state.loanAmount = loanAmount;
      state.bankName = bankName;
      state.van = van;
      state.overdueAmountTillDate = overdueAmountTillDate;
      state.vanRoutingCodeType = vanRoutingCodeType;
      state.vanRoutingCodeValue = vanRoutingCodeValue;
    },
  },
});

const repaymentReducer = repayment.reducer;

export const repaymentActions = repayment.actions;

export default repaymentReducer;
