/* eslint-disable react/require-default-props */
interface IIcon {
  color?: string;
  height?: string;
  width?: string;
}
export default function HomeIcon({color, height, width}: IIcon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z' fill={color} />
    </svg>
  );
}

HomeIcon.default = {
  width: '20',
  height: '17',
  color: '#121212',
};
