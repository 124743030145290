import styled from 'styled-components';

const StepperContainer = styled.div`
  .stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  .stepper--vertical {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;
  }
  .stepper--horizontal {
    width: 100%;
    /* margin-left: 20px; */
    @media (min-width: 1120px) {
      display: none;
    }
  }

  .stepper_content {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .stepper__step {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
  }

  .stepper--vertical .stepper__step {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .stepper--horizontal .stepper__step {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    position: relative;
    margin-top: 10px;
  }

  .stepper--vertical .stepper_content {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .stepper--horizontal .stepper_content {
    /* margin-left: 15px; */
    width: 100%;
    position: relative;
  }

  .stepper__node {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #9e9e9e;
    transition: background-color 0.3s, color 0.3s;
    border: 1px solid #4650ef;
    flex-shrink: 0;
  }

  .stepper__step--last {
    width: 0px;
  }

  .stepper__node--active {
    background-color: #d4d8fc;
    color: #ffffff;
  }

  .stepper__node--completed {
    background-color: #4650ef;
    color: #ffffff;
  }

  .stepper__name {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    @media (max-width: 1120px) {
      font-family: Nunito Sans;
      font-size: 10px;
      font-weight: 700;
      line-height: 13.6px;
      text-align: center;
      margin-top: 8px;
    }
  }

  .stepper--vertical .stepper__name {
    position: absolute;
    left: 35px;
    top: 0px;
    width: 115px;
    color: #757575;
  }
  .stepper--horizontal .stepper__name {
    /* position: absolute;
    right: 45px;
    top: 25px; */
    width: 60px;
    margin-left: -18px;
    color: #757575;
  }

  .stepper--vertical .stepper__name--active {
    color: #000000;
  }

  .stepper--horizontal .stepper__name--active {
    color: #000000;
  }

  .stepper__line {
    height: 1px;
    flex-grow: 1;
    background-color: #4650ef;
    transition: background-color 0.3s;
    /* margin: 0 8px; */
  }

  .stepper--vertical .stepper__line {
    width: 1px;
    height: 100px;
    margin-left: 12px;
  }

  .stepper__line--active {
    background-color: #4650ef;
  }

  /* @media (max-width: 600px) {
  .stepper__line {
    margin: 0 4px;
  }

  .stepper--vertical .stepper__line {
    margin: 4px 0;
  }
}

@media (max-width: 400px) {
  .stepper__line {
    margin: 0 2px;
  }

  .stepper--vertical .stepper__line {
    margin: 2px 0;
  }
} */
`;

export default StepperContainer;
