import {useState, useEffect} from 'react';
import {RootState, dispatch} from '@store';
import {
  loginActions,
  loginReducerLogout,
} from '@pages/Login/auth/store/loginReducer';
import {useSelector} from 'react-redux';

function IdleDetector() {
  const {idleTime} = useSelector((state: RootState) => state.login);
  const [isIdle, setIsIdle] = useState(false);
  let idleTimer: NodeJS.Timeout;

  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(() => {
      setIsIdle(true);
    }, idleTime);
  };

  const handleUserActivity = () => {
    setIsIdle(false);
    resetIdleTimer();
  };

  useEffect(() => {
    // Initial setup
    if (isIdle) {
      // dispatch(loginActions.logout());
      dispatch(loginReducerLogout());
      dispatch(loginActions.setIsShowOtp(false));
      dispatch(loginActions.setIsSelectBRN(false));
      dispatch(loginActions.setIsLogout(true));
      dispatch(loginActions.setIdleTimeout(true));
    }
    resetIdleTimer();

    // Event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(idleTimer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [isIdle]);

  return null;
}

export default IdleDetector;
